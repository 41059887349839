<template>
  <div class="devices">
    <div class="sub-wrap">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left">
            <img src="../../assets/icons/adone.svg" />
            <h2>{{ $t("global.schedules") }}</h2>
            <h3>{{ $t("schedule.description") }}</h3>
          </div>
          <div class="header-right">
            <p>
              <router-link to="/schedules/new" class="button button-ci button-round button-small">
                <img src="../../assets/icons/plus.svg" />
                <span>{{ $t("schedule.add") }}</span>
              </router-link>
            </p>
          </div>
        </div>

        <devices-nav></devices-nav>

        <div v-if="loading">
          <div class="loading-wrap">
            <div class="loading-spinner"></div>
          </div>
        </div>

        <div v-else>
          <div v-if="schedules.length == 0">
            <p>{{ $t("schedule.empty") }}</p>
          </div>

          <div v-else>
            <div class="row">
              <div v-for="schedule in schedules" v-bind:key="schedule.uuid">
                <div class="device-list-item">
                  <div class="item-content">
                    <router-link :to="'/schedules/' + schedule.uuid" class="device-img">
                      <img :src="require('../../assets/themes/' + _self.$theme + '/icon-schedule.png')" />
                    </router-link>

                    <router-link :to="'/schedules/' + schedule.uuid" class="device-img">
                      <div class="device-title">
                        <h3>{{ schedule.name }}</h3>
                      </div>
                    </router-link>
                  </div>
                  <a v-if="schedule.name == $t('schedule.defaultIntern')"></a>
                  <a v-else-if="schedule.name == $t('schedule.defaultExtern')"></a>
                  <a @click="delete_schedule(schedule.uuid)" v-else class="device-delete material-icons">delete</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";
import DevicesNav from "../../components/DevicesNav.vue";

export default {
  name: "playlists",
  components: { DevicesNav },
  data() {
    return {
      errors: [],
      loading: true,
      schedules: [],
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/schedules", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.loading = false;
          this.schedules = response.data.schedules;
        });
    },
    delete_schedule(uuid) {
      if (confirm(this.$t("schedule.deletePrompt"))) {
        axios
          .delete(process.env.VUE_APP_BASE_API + "/schedules/" + uuid, {
            headers: { Authorization: this.$store.getters.getToken },
          })
          .then((response) => {
            this.$notify({
              group: "notification",
              type: "success",
              text: this.$t("schedule.deleted"),
            });
            console.log(response);
            this.get_data();
          });
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.device-list-item {
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  padding: 0px;
  border-radius: 5px;
  display: block;
  color: #444;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 15px;
  overflow: hidden;

  transition: all 0.25s ease;

  &:hover {
    transform: translateX(5px);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease;
  }

  .item-content {
    display: flex;
    align-items: center;
  }

  a {
    display: block;
    color: #444;

    &:hover {
      text-decoration: none;
    }
  }

  h3 {
    margin: 0;
    font-size: 18px;
  }

  img {
    height: 45px;
    display: block;
    margin-right: 20px;
  }

  .device-delete {
    margin-right: 20px;
  }
}
</style>
